import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Emerald({ styles, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("content_text").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-flex">
        <div className="formkit-image-section">
          <div
            style={{
              backgroundColor: styles.forElement("column_left_bg_image")
                .backgroundColor
            }}
            className="formkit-bg-color"
          />
          <div
            style={styles.forElement("column_left_bg_image")}
            className="formkit-bg-image"
          />
          <div className="formkit-image-container">
            <div className="formkit-container">
              <Elements.Image
                className="formkit-image"
                name="image"
                size={{ w: 350, h: 350 }}
              />
              <div className="formkit-description">
                <Elements.Heading
                  className="formkit-image-header"
                  name="image_header"
                  tag="h2"
                  defaults={{
                    content: "Podcast Description"
                  }}
                />
                <Elements.Region
                  className="formkit-description-content"
                  name="image_description"
                >
                  <Elements.Content
                    defaults={{
                      content:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum dolor ac mi"
                    }}
                  />
                </Elements.Region>
              </div>
            </div>
          </div>
        </div>
        <div className="formkit-page-content">
          <div
            style={{
              backgroundColor: styles.forElement("column_right_bg_image")
                .backgroundColor
            }}
            className="formkit-bg-color"
          />
          <div
            style={styles.forElement("column_right_bg_image")}
            className="formkit-bg-image"
          />
          <div className="formkit-content-padding">
            <div className="formkit-container  formkit-form-section">
              <Elements.Heading
                className="formkit-preheader"
                name="preheader"
                tag="h2"
                defaults={{
                  content: "New episode"
                }}
              />
              <Elements.Heading
                className="formkit-heading"
                name="heading"
                defaults={{
                  content: "Lorem ipsum dolor sit amet, consectetur."
                }}
              />
              <Elements.Region className="formkit-content" name="content">
                <Elements.Content
                  defaults={{
                    content:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a aliquet justo. Suspendisse massa purus, egestas ut rutrum in, interdum vel nulla."
                  }}
                />
              </Elements.Region>
              <Elements.Form>
                <Elements.Errors />
                <Elements.CustomFields
                  style={{
                    color: checkboxes_color
                  }}
                >
                  <Elements.AddFieldButton />
                  <Elements.Button
                    name="submit"
                    group="button"
                    defaults={{ content: "Sign up today" }}
                  />
                </Elements.CustomFields>
                <Elements.Content
                  className="formkit-disclaimer"
                  name="disclaimer"
                  defaults={{
                    content: "We respect your privacy. Unsubscribe at any time."
                  }}
                />
              </Elements.Form>

              <Elements.BuiltWith
                background="column_right_bg_image"
                image="column_right_bg_image"
              />
            </div>
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Emerald.style = "landing_page"
Emerald.categories = ["Podcast"]
Emerald.thumbnail = ""
Emerald.preview = "https://demo.ck.page/emerald"
Emerald.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "last_name",
    label: "Your last name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Emerald, { name: "Emerald" })
